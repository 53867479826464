
// Fonts
@import url('https://fonts.googleapis.com/css?family=Exo+2:100,400,700&amp;subset=cyrillic');

// Variables
@import "variables";

// Bootstrap
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";
@import "~fancybox/dist/scss/jquery.fancybox";



html, body {
    background-color: $body-bg;
    color: $text-color;
    font-family: $font-family;
    font-size: $font-size-base;
    line-height: $line-height-base;
    font-weight: 400;
    height: 100vh;
    margin: 0;
}
[v-cloak] { display: none }
.form-group {
  position: relative;
}
.flex-middle {
  display: flex;
  align-items: center;
}
.btn-red {
  @include button-variant(#fff, $brand-lb, darken($brand-lb, 10%));
}
.panel {
  &-number {
    border: 2px solid;
    width: 28px;
    height: 28px;
    border-radius: 14px;
    padding: 3px 1px 1px 0px;
    text-align: center;
    font-size: 18px;
    margin: -5px 10px -5px 0;
    flex-shrink: 0;
  }
  &-title {
    margin: 5px 0;
  }
}
.href {
  cursor: pointer;
}

@import "welcome";

.subnavbar {
  margin-top:-22px;
  position: relative;
  min-height: 25px;
  margin-bottom: 22px;
  border: 1px solid transparent;
  background: #f2f2f2;

  & li > a {
    padding-top: 5px;
    padding-bottom: 5px;
    color: #555;
  }

  & .active > a {
    background-color: #eaeaea;
    color: #333;
  }
}

.content {
  transition: all 1s;
  opacity: 1;
  z-index: 1;

  &.loading {
    opacity: 0.2 !important;
  }
}
.preloader {
  position: absolute;
  text-align: center;
  margin: 100px 0;
  width: 100%;
  top: 0px;
  left: 0px;
  z-index: 10;
}


._form {
  hr {
    margin: 2px 0 10px 0;
  }
  label {
    margin-bottom: 0px;
  }
  h3 {
    font-size: 16px;
    font-weight: bold;
    color: #000;
    margin: 10px 0 5px 0;
  }
  & .form-control.i75 {
    max-width: 75px;
    min-width: 75px;
    width: 100%;
  }
  & .form-control.i100 {
    max-width: 100px;
    min-width: 100px;
    width: 100%;
  }
  & .form-control.i150 {
    max-width: 150px;
    min-width: 150px;
    width: 100%;
  }
  & .form-control.i200 {
    max-width: 200px;
    min-width: 200px;
    width: 100%;
  }
  &-checkbox {
    display: block;
    width: 100%;
  }
  &-alert {
    padding: 5px 15px;
    margin-bottom: 22px;
    border: 1px solid transparent;
    border-radius: 4px;
    margin: 0 auto 22px auto;
    @media(min-width: $screen-md-min) {
      width: 80%;
    }
    @media(max-width: $screen-xs-max) {
      font-size: 12px;
      line-height: 15px;
    }
    &-danger {
      background-color: #f2dede;
      border-color: #ebccd1;
      color: #a94442;
    }
    &-success {
      color: #3c763d;
      background-color: #dff0d8;
      border-color: #d6e9c6;
    }

    h4 {
      margin-left: 25px;
      font-weight: bold;
      text-transform: uppercase;
    }
    p {
      margin-left: 25px;
    }

    ul {
      padding-left: 25px;
    }
  }
  &-selector {
    &-item:first-child {
      border-top-left-radius: 17px;
      border-bottom-left-radius: 17px;
      padding-left: 15px
    }
    &-item:last-child {
      border-top-right-radius: 17px;
      border-bottom-right-radius: 17px;

      border-right-width: 1px;
      padding-right: 15px
    }
    &-item {
      border-style: solid;
      border-width: 1px 0 1px 1px;
      border-color: #ccd0d2;
      padding: 5px 10px;
      color: #555555;
      display: inline-block;
      float:left;
      margin: 0px;

      background: #fff;
      &:hover {
        background: #fbd1d1;
        cursor:pointer;
      }
      &.selected, &.selected:hover {
        background: #a51312; //ed1c1a
        color: #fff;
      }
    }
  }
  &-group {
    display:flex;
    align-items: center;
    margin: 0 -15px;
    flex-wrap: wrap;
    padding: 0 0 8px 0;

    @media(max-width: $screen-xs-max) {
      flex-direction: column;
      align-items: stretch;
      border-bottom: 1px solid #f0f0f0;
      padding: 4px 0;
    }

    &.has-error {
      & ._form-label, & ._form-desc {
        color: #a94442;
      }
      & ._form-input input[type=text],& ._form-input input[type=number] {
        border-color: #a94442;
      }
    }
  }
  &-label {
    padding: 0 15px;
    width: 100%;
    max-width: 23%;
    @media(max-width: $screen-md-max) {
      max-width: 23%;
    }
    @media(max-width: $screen-sm-max) {
      max-width: 25%;
    }

    text-align: right;
    align-self: center;
    flex-shrink: 0;

    @media(max-width: $screen-xs-max) {
      align-self: flex-start;
      text-align: left;
      max-width: 100%;
    }
  }
  &-right {
    width: 100%;
    max-width: 77%;
    padding: 0 15px;
    @media(max-width: $screen-md-max) {
      max-width: 77%;
    }
    @media(max-width: $screen-sm-max) {
      max-width: 75%;
    }

    display:flex;
    align-items: center;

    @media(max-width: $screen-xs-max) {
      flex-direction: column;
      align-items: stretch;
      max-width: 100%;
    }
  }

  &-input {
    //padding: 0 15px;
    width: auto;

    @media(max-width: $screen-xs-max) {
      max-width: 100% !important;
    }

    &.wFull {
      width: 100%;
    }
    
    &-offset {
        margin: 5px 0;
    }

    & input[type=text], & input[type=number], & select, & textarea, input[type=date] {
      border-style: solid;
      border-width: 1px;
      border-radius: 17px;
      padding: 5px 15px;
      display: inline-block;
      margin-right: 5px;
    }
    & select option {
      padding: 5px 10px;
    }

    & input[type=radio], & input[type=checkbox] {
      position: relative;
      top: 2px;
    }

    &-flex {
      display:flex;
      align-items: center;
      flex-wrap: wrap;
    }
    &-col {
      align-self: flex-start;
      margin-right: 15px;
      display: flex;
      align-items: baseline;

      &.w50 {
        width: 50%;
      }
      &.w100 {
        width: 100%;
      }
    }
    &-error {
      padding: 0 15px;
      color: #a94442;
      margin-bottom: 5px;
      width: 100%;
      max-width: 75%;
      margin-left: 23%;
      @media(max-width: $screen-md-max) {
        margin-left: 23%;
      }
      @media(max-width: $screen-sm-max) {
        margin-left: 25%;
      }
      @media(max-width: $screen-xs-max) {
        margin-left: 0;
        max-width: 100%;
      }

      font-size: 12px;
      line-height: 15px;
    }
  }
  &-desc {
    padding: 0 15px;
    width: 100%;
    max-width: 50%;

    @media(max-width: $screen-xs-max) {
      max-width: 100%;
    }

    font-size: 12px;
    line-height: 15px;

    margin: 5px 0;
  }
}

@import "media";

.ratio-4-3 {
    width:100%;
    position:relative;
    background: 50% 50% no-repeat;
    background-size:cover;
    background-clip:content-box;

    &:before{
        display:block;
        content:"";
        padding-top:75%;
    }
}