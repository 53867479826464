.full-height {
    height: 100vh;
}

label.label_in_input {
  font-weight: 100;
  text-transform: uppercase;
  font-size: 18px;
  line-height: 18px;
  color: #000;
  margin-top: 5px;
  position: absolute;
  top: 10px;
  left: 10px;
}

input[type=text].label_in_input,input[type=password].label_in_input {
  padding-left: 100px;
}

.login-form {
  margin: 50px auto;

  & .checkbox {
    margin-top:0px;
    label {
      font-size: 15px;
      line-height: 22px;
    }
  }
}
.flex-center {
    align-items: center;
    display: flex;
    justify-content: center;
}


.m-b-md {
    margin-bottom: 30px;
}
