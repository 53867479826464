.media {
    position: relative;
    margin: 10px 0;
    width: 150px;

        
    &:hover {
        & .media-sort, & .media-delete, & .media-defaultPhoto {
            display: block;
        }
    }
    
    &_show {
        position: absolute;
        left: 0;
        top: 0;
        background: rgba(255,255,255,0.8);
        padding: 0px 6px;
        border-bottom-right-radius: 8px;
        z-index: 1;
    }
    &_show input {
        margin-top: 2px;
        position: relative;
        top: 2px;
    }
    &_show label {
        font-size: 0.8em;
        font-weight: bold;
    }
    &_title {
        display: block;
        width: 100%;
        font-size: 0.7em;
        margin-top: -30px;
    }
    
    &-defaultPhoto {
        z-index: 1;
        display: none;
        position: absolute;
        top: 0;
        left: 15px;
        right: 15px;
        text-align: center;
        border-radius: 0px 0px 8px 8px ;
        background: #000;
        color: #fff
    }
    
    &-sort {
        z-index: 1;
        display: none;
        position: absolute;
        bottom: 0;
        left: 0;
        background: yellow;
        border-top-right-radius: 8px;
        width: 80px;
        padding: 4px 6px;
        font-size: 0.8em;
        border: 0px;

        &:focus {
            padding: 6px 8px 4px 6px;
        }
    }

    &-delete {
        z-index: 1;
        display: none;

        position: absolute;
        bottom: 0;
        right: 0;
        background: red;
        color: #fff;
        text-transform: uppercase;
        padding: 4px 6px;
        border-top-left-radius: 8px;
        font-size: 0.8em;
        font-weight: bold;

        &:hover {
            color: #fff;
            text-decoration: none;
            padding: 6px 6px 4px 8px;
        }
    }

    &_youtube_play {
        background: url('/images/youtube_play.svg') no-repeat;
        height: 50%;
        background-size: 44%;
        z-index: 1;
        position: absolute;
        top: 31%;
        left: 35%;
        width: 70%;
    }
}